import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate
} from 'react-router-dom';

// PAGES
import Home from '../../pages/Home';
import DoNotSell from '../../pages/DoNotSell';
import Faq from '../../pages/Faq';
import Status from '../../pages/Status';
import Layout from '../Layout';
import PageNotFound from '../../pages/404';
import NotFound from '../../pages/NotFound';

const AppRouter = () => {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route path='/' element={<Home />} />
					<Route path='donotsell' element={<DoNotSell />} />
					<Route path='faq' element={<Faq />} />
					<Route path='status' element={<Status />} />
					<Route path='not-found' element={<NotFound />} />
					<Route path='home' element={<Navigate to='/' />} />
					<Route path='*' element={<PageNotFound />} />
				</Route>
			</Routes>
		</Router>
	);
};

export default AppRouter;
