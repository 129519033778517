// TEMPORARY
const codeMaps = {
	204: 'No data exists'
};

export const handleAxiosError = (error) => {
	const errorCode = error;

	if (errorCode) {
		const mappedCode = codeMaps[errorCode];
		throw new Error(mappedCode || 'Request failed. Please try again.');
	} else {
		throw new Error('Request failed. Please try again.');
	}
};
