import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { primaryAPI } from '../../utilities/apiEndpointUtility';

import {
	refundStatus,
	refundStatusCompleted,
	refundStatusFailed
} from './refundStatusSlice';
import { handleAxiosError } from '../../utilities/errorUtility';

const refundStatusEpic = (action$) =>
	action$.pipe(
		ofType(refundStatus),
		mergeMap(async (action) => {
			const subscriberNumber = action.payload.subscriberNumber;
			try {
				const response = await axios.get(
					`${primaryAPI()}/membership-refund-status/${subscriberNumber}`
				);
				return response.data;
			} catch (error) {
				handleAxiosError(error);
			}
		}),
		switchMap((res) => [refundStatusCompleted(res)]),
		catchError((error, source) =>
			merge(of(refundStatusFailed(error.message)), source)
		)
	);

export default [refundStatusEpic];
