import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import bannerImage from '../../../assets/membershipsBanner.jpg';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const StyledLink = styled(Link)(({ theme }) => ({
	fontSize: '70px',
	letterSpacing: '-1px',
	fontWeight: '500',
	margin: 0,
	color: theme.palette.primary.main
}));

const StyledSpan = styled('span')(({ theme }) => ({
	fontWeight: 600,
	color: theme.palette.secondary.main
}));

const Header = ({ t }) => {
	return (
		<Box
			component='header'
			sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}
		>
			<Typography component='h1'>
				<StyledLink to='/' style={{ textDecoration: 'none' }}>
					Membership <StyledSpan>Refund Status</StyledSpan>
				</StyledLink>
			</Typography>
			<img
				src={bannerImage}
				width='100%'
				alt={t('common:headerImgAltTag')}
			></img>
		</Box>
	);
};

Header.propTypes = {
	t: PropTypes.func
};

export default withTranslation('common')(Header);
