import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import TextInput from '../../components/TextInput';

import { refundStatus } from '../../containers/RefundStatus/refundStatusSlice';
import { captchaValid } from '../../containers/CaptchaValid/captchaValidSlice';

import helperImage from '../../assets/sub-num-help.jpeg';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const StyledFormBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '25px'
}));

const StyledButton = styled(Button)(({ theme }) => ({
	width: 300,
	fontSize: '1.2em',
	fontWeight: 'bold',
	color: theme.palette.common.white
}));

const defaultValues = {
	subscriberNumber: ''
};

const Home = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const captchaRef = useRef();

	const { handleSubmit, control, trigger, reset } = useForm({
		defaultValues
	});
	const [openSubNumberDialog, setOpenSubNumberDialog] = React.useState(false);

	const { data, error, hasCompleted } = useSelector(
		(state) => state.refundStatusSlice.refundStatus
	);

	React.useEffect(() => {
		reset();
	}, []);

	React.useEffect(() => {
		if (!error && data && hasCompleted) {
			navigate('/status');
		}
	}, [error, data, hasCompleted]);

	React.useEffect(() => {
		if (!error && !data && hasCompleted) {
			navigate('/not-found');
		}
	}, [error, data, hasCompleted]);

	const validateField = async (fieldName) => {
		try {
			await trigger(fieldName);
		} catch (err) {
			console.log('err', err);
		}
	};

	const validateCaptcha = () => {
		const captchaValue = captchaRef.current.getValue();
		if (captchaValue) {
			dispatch(captchaValid(captchaValue));
		}
	};

	const onSubmit = (data, e) => {
		e.preventDefault();
		validateCaptcha();
		dispatch(refundStatus(data));
	};
	return (
		<React.Fragment>
			<Typography
				component='h2'
				sx={{ fontSize: '2em', fontWeight: 'bold', marginBottom: '10px' }}
			>
				{t('home:welcomeHeader')}
			</Typography>
			<Typography component='p' sx={{ margin: '15px 0' }}>
				{t('home:welcomeText')}
			</Typography>
			<StyledFormBox component='section'>
				<Stack
					direction='row'
					flexWrap='wrap'
					justifyContent='space-between'
					spacing={2}
				>
					<Box sx={{ width: '45%' }}>
						<Typography
							component='p'
							sx={{ color: (theme) => theme.palette.error.main }}
						>
							* = {t('home:formElements.requiredField')}
						</Typography>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='subscriberNumber'
							control={control}
							helperText={
								<Link
									onClick={() => setOpenSubNumberDialog(true)}
									sx={{ fontSize: '12px' }}
								>
									{t('home:formElements.subMemberNumber-subText')}
								</Link>
							}
							label={`${t('home:formElements.subMemberNumber')}*`}
							onChangeValidate={() => validateField('subscriberNumber')}
						/>
					</Box>
					<Box
						width='100%'
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
					>
						<Box mb='15px'>
							<ReCAPTCHA
								sitekey='6Lf3JtcpAAAAADBDBl9Z3JZgDql6VsJbg0Ko8RJT'
								ref={captchaRef}
							/>
						</Box>

						<StyledButton
							variant='contained'
							color='success'
							size='large'
							onClick={handleSubmit(onSubmit)}
						>
							{t('common:buttons.submit')}
						</StyledButton>
					</Box>
				</Stack>
			</StyledFormBox>
			<Dialog
				open={openSubNumberDialog}
				onClose={() => setOpenSubNumberDialog(false)}
				aria-labelledby='subscriber-number-help-title'
				aria-describedby='subscriber-number-help-description'
			>
				<DialogContent>
					<img src={helperImage} alt={t('home:subscriberHelperAltText')} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenSubNumberDialog(false)}>
						{t('common:buttons.close')}
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

Home.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['home', 'common'])(Home);
