import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { primaryAPI } from '../../utilities/apiEndpointUtility';

import {
	doNotSell,
	doNotSellCompleted,
	doNotSellFailed
} from './doNotSellSlice';
import { handleAxiosError } from '../../utilities/errorUtility';

let doNotSellMyInfoRequest = {};

const doNotSellEpic = (action$) =>
	action$.pipe(
		ofType(doNotSell),
		mergeMap(async (action) => {
			try {
				doNotSellMyInfoRequest = action.payload;
				const response = await axios.post(
					`${primaryAPI()}/doNotSellMyInfo`,
					doNotSellMyInfoRequest
				);

				return response.data;
			} catch (error) {
				handleAxiosError(error?.response?.status);
			}
		}),
		switchMap(() => [doNotSellCompleted(doNotSellMyInfoRequest)]),
		catchError((error, source) =>
			merge(of(doNotSellFailed(error.message)), source)
		)
	);

export default [doNotSellEpic];
