import { configureStore } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { catchError } from 'rxjs/operators';

// SLICES
import refundStatusSlice from '../containers/RefundStatus/refundStatusSlice';
import doNotSellSlice from '../containers/DoNotSell/doNotSellSlice';
import captchaValidSlice from '../containers/CaptchaValid/captchaValidSlice';

// EPICS
import refundStatusEpic from '../containers/RefundStatus/refundStatusEpics';
import doNotSellEpic from '../containers/DoNotSell/doNotSellEpics';
import captchaValidEpics from '../containers/CaptchaValid/captchaValidEpics';

const epicMiddleware = createEpicMiddleware();

const epics = combineEpics(
	...refundStatusEpic,
	...doNotSellEpic,
	...captchaValidEpics
);

const rootEpic = (action$, store$, dependencies) =>
	epics(action$, store$, dependencies).pipe(
		catchError((error, source) => {
			console.error(error);
			return source;
		})
	);

export const store = configureStore({
	reducer: {
		refundStatusSlice,
		doNotSellSlice,
		captchaValidSlice
	},
	middleware: [epicMiddleware]
});

epicMiddleware.run(rootEpic);
