import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	refundStatus: {
		data: {},
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const refundStatusSlice = createSlice({
	name: 'refundStatus',
	initialState,
	reducers: {
		refundStatus: (state) => {
			state.refundStatus.isLoading = true;
			state.refundStatus.error = undefined;
		},
		refundStatusCompleted: (state, action) => {
			state.refundStatus.isLoading = false;
			state.refundStatus.hasCompleted = true;
			state.refundStatus.data = action.payload;
		},
		refundStatusFailed: (state, action) => {
			state.refundStatus.isLoading = false;
			state.refundStatus.error = action.payload;
		},
		refundStatusReset: (state) => {
			state.refundStatus = initialState.refundStatus;
		}
	}
});

export const {
	refundStatus,
	refundStatusCompleted,
	refundStatusFailed,
	refundStatusReset
} = refundStatusSlice.actions;

export default refundStatusSlice.reducer;
