import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NotFound = () => {
	return (
		<Box>
			<Typography>
				There is no refund information available for the supplied membership
				number.
			</Typography>
			<Typography>
				If you recently requested a refund, please allow two business days for
				your information to appear.
			</Typography>
		</Box>
	);
};

export default NotFound;
