import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const StatusStack = styled(Stack)(({ theme }) => ({
	width: '100%',
	border: `2px solid ${theme.palette.grey[300]}`,
	borderRadius: 5,
	padding: 10
}));

const StatusChip = styled(Chip)(({ theme }) => ({
	color: theme.palette.secondary.light
}));

const Status = ({ t }) => {
	const navigate = useNavigate();

	const { data, hasCompleted } = useSelector(
		(state) => state.refundStatusSlice.refundStatus
	);

	React.useEffect(() => {
		if (!hasCompleted || !data) {
			navigate('/home');
		}
	}, []);
	if (!hasCompleted || !data) {
		return null;
	}
	return (
		<React.Fragment>
			<Typography component='h2'>
				{t('status:refundStatusMembershipNumber')}{' '}
				<Typography component='span' fontSize='1.2em' fontWeight='bold'>
					{data[0]?.membershipNumber}
				</Typography>
			</Typography>
			<Stack direction='column' gap={2} elevation={0}>
				{!!data &&
					data?.map((status, key) => {
						return (
							<StatusStack
								key={key}
								direction='row'
								gap={5}
								alignItems='center'
								justifyContent='space-between'
								flexWrap='wrap'
							>
								<Stack
									gap='15px'
									direction='row'
									alignItems='center'
									minWidth={160}
								>
									<CreditCardIcon />
									<Typography sx={{ fontWeight: 'bold' }}>
										{status.membershipClubName}
									</Typography>
								</Stack>
								<Stack>
									<Typography sx={{ fontSize: '0.8em' }}>
										{t('status:originalDateCharged')}
									</Typography>
									<Typography sx={{ fontWeight: 'bold' }}>
										{status.originalDateCharged}
									</Typography>
								</Stack>

								<Stack>
									<Typography sx={{ fontSize: '0.8em' }}>
										{t('status:amountCredited')}
									</Typography>
									<Typography sx={{ fontWeight: 'bold' }}>
										{status.amountCredited}
									</Typography>
								</Stack>
								<Stack>
									<Typography sx={{ fontSize: '0.8em' }}>
										{t('status:creditCard')}
									</Typography>
									<Typography sx={{ fontWeight: 'bold' }}>
										{status.creditCardType}
									</Typography>
								</Stack>
								<Stack>
									<Typography sx={{ fontSize: '0.8em' }}>
										{t('status:last4CreditCard')}
									</Typography>
									<Typography sx={{ fontWeight: 'bold' }}>
										{status.last4CC}
									</Typography>
								</Stack>
								<Stack>
									<Typography sx={{ fontSize: '0.8em' }}>
										{t('status:statusDate')}
									</Typography>
									<Typography sx={{ fontWeight: 'bold' }}>
										{status.statusDate}
									</Typography>
								</Stack>
								<StatusChip label={status.status} />
							</StatusStack>
						);
					})}
			</Stack>
		</React.Fragment>
	);
};

Status.propTypes = {
	t: PropTypes.func
};

export default withTranslation('status')(Status);
